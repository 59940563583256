.image-viewer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
  .image-viewer-content {
    position: relative;
    text-align: center;
  }
  
  .image-viewer-img {
    max-width: 96% !important;
    max-height: 96vh !important;
    cursor: default !important;
  }
  
  .nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 65px;
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    user-select: none;
    opacity: 0.5;
    text-shadow: 
    -1px -1px 0 rgba(211, 211, 211, 0.8),
    1px -1px 0 rgba(211, 211, 211, 0.8),
    -1px 1px 0 rgba(211, 211, 211, 0.8),
    1px 1px 0 rgba(211, 211, 211, 0.8);
  }
  
  .prev-btn {
    left: 25px;
  }
  
  .next-btn {
    right: 25px;
  }
  
  .nav-btn:hover, .close-btn:hover {
    color: #aaa;
    opacity: 1 !important;
  }
  
  .image-viewer-overlay,
  .nav-btn,
  .close-btn {
    transition: color 0.25s;
  }
  