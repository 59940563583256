@font-face {
  font-family: "LuluHK"; /* Project id 4489119 */
  src: url('iconfont.woff2?t=1713626765633') format('woff2'),
       url('iconfont.woff?t=1713626765633') format('woff'),
       url('iconfont.ttf?t=1713626765633') format('truetype');
}

.LuluHK {
  font-family: "LuluHK" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.a-logowithtext_web:before {
  content: "\e641";
}

.preview_bottom:before {
  content: "\e642";
}

.bin:before {
  content: "\e633";
}

.exclamation:before {
  content: "\e634";
}

.edit:before {
  content: "\e635";
}

.payment_line:before {
  content: "\e636";
}

.map:before {
  content: "\e637";
}

.cart_fill:before {
  content: "\e638";
}

.pin_blue:before {
  content: "\e639";
}

.lang:before {
  content: "\e63a";
}

.home_line:before {
  content: "\e63b";
}

.Reload:before {
  content: "\e63c";
}

.No:before {
  content: "\e63d";
}

.time_line:before {
  content: "\e63e";
}

.cart_line:before {
  content: "\e63f";
}

.location_line:before {
  content: "\e640";
}

.account:before {
  content: "\e61f";
}

.settings_fill:before {
  content: "\e620";
}

.Add:before {
  content: "\e621";
}

.restorecart:before {
  content: "\e622";
}

.order:before {
  content: "\e623";
}

.i:before {
  content: "\e624";
}

.saveasimage:before {
  content: "\e625";
}

.logo_roundblue:before {
  content: "\e626";
}

.copy_line:before {
  content: "\e627";
}

.ngo:before {
  content: "\e628";
}

.call_line:before {
  content: "\e629";
}

.contact:before {
  content: "\e62a";
}

.page_fill:before {
  content: "\e62b";
}

.location_fill:before {
  content: "\e62c";
}

.home_fill:before {
  content: "\e62d";
}

.copy_circle_line:before {
  content: "\e62e";
}

.tick:before {
  content: "\e62f";
}

.merchant_preview:before {
  content: "\e630";
}

.filter:before {
  content: "\e631";
}

.cross:before {
  content: "\e632";
}

.pin_selected:before {
  content: "\e612";
}

.reset:before {
  content: "\e60e";
}

.luluhk_cus:before {
  content: "\e610";
}

.category:before {
  content: "\e60f";
}

.page_line:before {
  content: "\e611";
}

.settings_line:before {
  content: "\e613";
}

.flip:before {
  content: "\e614";
}

.pin:before {
  content: "\e615";
}

.copy_circle_fill:before {
  content: "\e616";
}

.logo_round:before {
  content: "\e617";
}

.mail_line:before {
  content: "\e618";
}

.chat:before {
  content: "\e60d";
}

.logo:before {
  content: "\e619";
}

.search:before {
  content: "\e61a";
}

.merchant:before {
  content: "\e61b";
}

.logo_squareblue:before {
  content: "\e61c";
}

.pin_red:before {
  content: "\e61d";
}

.store:before {
  content: "\e61e";
}

