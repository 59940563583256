/*.static-web-styling html, body{
    height:100%;
    overflow:hidden;
    font-family:'Calibri',"Microsoft Yahei UI Light","微軟雅黑","微软雅黑",sans-serif;
}*/

/* Navigation Bar */
.static-web-styling .navbar{
    text-transform:uppercase;
    font-weight:300;
    font-size:1.2rem;
    letter-spacing:.025rem;
    background-color:white!important;
    border-bottom-color:#D3D3D3!important;
}
/*.static-web-styling .navbar-brand {
    margin-left: 16px;
}*/
.static-web-styling .navbar-brand img{
    padding-left:.4rem;
    height:3rem;
}
.static-web-styling .navbar-nav li{
    padding:0 0.8rem 0 1.8rem;
}
/*.static-web-styling .navbar-toggler{
    margin-right: 16px;
    margin-left: 2px;
}*/
.static-web-styling .navbar-light .navbar-nav .nav-link{
    color:grey;
}
.static-web-styling .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link:hover{
    color:#2594a2;
    font-weight:800;
}
.static-web-styling .collapse{
    max-height:50vh;
    overflow-y:auto;
    /*margin-right: 16px;
    margin-left: 2px;
    text-align: left;*/
}
/*.static-web-styling .navbar-content-top-right{
    margin-right: 16px;
    margin-left: 2px;
}*/
/*.static-web-styling .navbar-content-bottom{
    margin-left: 16px;
}*/
.static-web-styling .terms{
    line-height: 1.5;
}

/* Bottom Navigation Bar */
.static-web-styling #bottomnav{
    border-top-color: #D3D3D3!important;
}
.static-web-styling .navbar-nav .nav-item-left{
    padding:.3rem 2rem .3rem .8rem;
}
.static-web-styling .navbar-nav .nav-item-right{
    padding:0 .8rem 0 2rem;
}
.static-web-styling .copyright{
    text-transform:capitalize;
    font-size:.95rem;
    letter-spacing:-.025rem;
    color:grey;
    text-align:right;
    padding-left:.3rem;
}
.static-web-styling svg{
    width:30px;
    height:30px;
    fill:grey;
}
.static-web-styling svg:hover{
    fill:#2594a2;
}

/* Middle Container & Home */
.static-web-styling .container{
    position:absolute;
    top:50px;
    bottom:68px;
    right:0;
    left:0;
    overflow-y:auto;
    width:100%;
    max-width:100%;
    z-index:1;
}
.static-web-styling .content{
    padding:4.5rem 0 2rem;
}
.static-web-styling .logo-text{
    width:31rem;
    padding:0 3rem 1.5rem;
}
.static-web-styling .flex-sm-row{
    padding:0 2.8rem;
}
.static-web-styling .app-store{
    height:3rem;
    margin:1rem .5rem .5rem;
}
.static-web-styling .modal-title{
    color:#2594a2;
    font-weight:600;
}
/* Terms & Privacy */
.static-web-styling .terms{
    width:1000px;
    max-width:100%;
    margin:1.8rem auto 2.5rem;
    padding-left: 0.8rem;
    padding-right: 0.5rem;
}
.static-web-styling hr{
    background-color:#D3D3D3;
}
.static-web-styling h1{
    text-align:left;
    font-size:2.3rem;
    font-weight:600;
    color:#2594a2;
    padding-top: 33px;
}
.static-web-styling h2{
    display:inline-block;
    text-decoration:underline;
    font-size:1.1rem;
    font-weight:600;
    max-width:calc( 100% - 2rem );
    margin:0px;
    /*margin:2.8px 0 16px;*/
}

/* Definition List */
.static-web-styling dl{
    display:table;
    border-collapse:collapse;
}
.static-web-styling dl div{
    display:table-row
}
.static-web-styling dt{
    text-align:left;
    font-weight:600;
}
.static-web-styling dt, dd{
    display:table-cell;
    border:1px solid #D3D3D3;
    vertical-align:middle; 
    padding:.3rem;
}

/* First-level ordered list */
.static-web-styling .ol-h2{
    counter-reset:item;
    margin-left:0px;
    padding-left:0px;
}
.static-web-styling .ol-h2 .ol-h2-li{
    display:block;
}
.static-web-styling .ol-h2 .ol-h2-li:before{ 
    display:inline-block;
    vertical-align:top;
    content:counter(item, decimal) ". ";
    counter-increment:item;
    width:2rem;
    /*margin-bottom:16px;*/
    font-size:1.1rem;
    font-weight:600;
}

/* Second-level ordered list */
.static-web-styling .ol-li2{
    counter-reset:item;
}
.static-web-styling .ol-li2 .ol-li2-li{
    display:block;
    margin-left:1.2rem;
}
.static-web-styling .ol-li2 .ol-li2-li:before{ 
    display:inline-block;
    content:counter(item, lower-alpha) ". ";
    counter-increment:item;
    width:2rem;
    margin-left:-2rem;
}

/* Third-level ordered list */
.static-web-styling .ol-li3{
    counter-reset:item;
}
.static-web-styling .ol-li3 .ol-li3-li{
    display:block;
    margin-left:-1rem;
}
.static-web-styling .ol-li3 .ol-li3-li:before{ 
    display:inline-block;
    content:counter(item, upper-roman) ". ";
    counter-increment:item;
    width:2.5rem;
    margin-left:-2.5rem;
}

/* Third-level unordered list */
.static-web-styling .ul-li3{
    list-style-type:disc;
}
.static-web-styling .ul-li3 li{
    margin-left:-.3rem;
}

/* Fourth-level unordered list */
.static-web-styling .ul-li4{
    list-style-type:disc;
}

/* Screen Adjustment*/
@media (max-width:991px){
    .static-web-styling .app-store{
        height:3rem;
        margin:1rem .5rem .5rem;
    }
    .static-web-styling .navbar-nav li{
        padding:.9rem .8rem .6rem 1.6rem;
    }
}
@media (max-width:767px){
    .static-web-styling .navbar-nav .nav-item-left{
        padding:.3rem 2rem .3rem .2rem;
    }
    .static-web-styling .copyright{
        padding-right:.3rem;
    }
    .static-web-styling .content{
        padding:3.5rem 0 2rem;
    } 
    .static-web-styling .app-store{
        height:2.8rem;
        margin:1rem .5rem .5rem;
    } 
}
@media (max-width:575px){
    .static-web-styling .navbar-nav .nav-item-left{
        padding:.3rem 1.5rem .3rem .2rem;
    }
    .static-web-styling .content{
        padding:2.5rem 0 1rem;
    }
    .static-web-styling .app-store{
        width:9rem;
        height:auto;
        margin:.6rem .5rem .5rem;
    }
}
