
#tab-demo .ant-tabs-nav-list {
    display: block;
    margin: 0;
    list-style: none;
}
#tab-demo .ant-tabs-nav-list > .ant-tabs-tab {
    display: inline-block;
    vertical-align: top;
    margin: 0 -1px -1px 0;
    height: 50px;
    width: 30%;
    line-height: 50px;
    background: #FFFFFF;
    padding: 0 15px;
    list-style: none;
    box-sizing: border-box;
}
#tab-demo .ant-tabs-nav-list > .ant-tabs-tab > .ant-tabs-tab-btn {
    color: #000000;
    text-decoration: none;
    font-size: 24px;
}
#tab-demo .ant-tabs-nav-list > .ant-tabs-tab-active > .ant-tabs-tab-btn {
    border-bottom: 3px solid #2594A2;
    background: #FFFFFF;
    color: #2594A2;
}
#tab-demo .tab-inner:not(.active){
    display: none;
}
